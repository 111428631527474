<template>
  <div class="about">
    <!-- <Menu/>
    <h1>This is an about page</h1> -->
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>

<style lang="scss"></style>
